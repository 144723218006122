/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';

import TableTabView from '../productScreens/TableTabView';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';

import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { useDispatch } from 'react-redux';
import { getAllUserDetails, getRoles } from '../../../redux/rootActions';
import SearchBar from '../../ui/molecules/SearchBar';
import { Link, useHistory } from 'react-router-dom';
import { Buttons } from '../../ui/atoms/Button';
import AddUserOptionModal from '../../templates/modals/AddNewUserModal';
import InviteUserFormModal from '../../templates/modals/InviteUserformModal';
import {
    ERROR_STATUS_UPDATE_GROUP,
    INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP,
    INSTANT_EDIT_OPTION_EDIT_PRODUCT_GROUP,
    INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP,
    INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP,
    INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP,
    INSTANT_EDIT_OPTION_VIEW_PRODUCT_GROUP,
    SUCCESSFULLY_STATUS_UPDATE_GROUP,
} from '../../../constants/productGroups';
import { ADD_PRODUCT_GROUPS, FETCH_PRODUCT_GROUPS } from '../../../queries/ProductGroupQueries';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ProductType } from '../../../enums/product';
import DataTable from '../../ui/organisms/DataTable';
import Toast from '../../ui/atoms/Toast';

export default function ProductGroupListScreen() {
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const history = useHistory();
    const [clickedGroup, setClickedGroup] = useState(null);
    const [productsGroupData, setproductsGroupData] = useState([]);

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [inviteUserFormModal, setInviteUserFormModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [message, setMessage] = useState('');
    const [statusUpdateGroupLoading, setStatusUpdateGroupLoading] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showAddNewUser, setShowAddNewUser] = useState(false);
    const [queryError, setQueryError] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [selectedProductType, setSelectedProductType] = useState(null);
    const [isSelectedProductTypeRemoved, setIsSelectedProductTypeRemoved] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [productGroupCounts, setProductGroupCounts] = useState({
        all: 0,
        active: 0,
        inActive: 0,
        trashed: 0,
    });

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const [getProductGroupData, { loading: productGroupsLoading }] = useLazyQuery(
        FETCH_PRODUCT_GROUPS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setproductsGroupData(data.productGroups.productGroups);
                setProductGroupCounts({
                    all: data.productGroups.totalProductGroups,
                    active: data.productGroups.totalActiveProductGroups,
                    inActive: data.productGroups.totalInactiveProductGroups,
                    trashed: data.productGroups.totalDeletedProductGroups,
                });
                setTotalItems(
                    selectedTab === 4
                        ? data.productGroups.totalDeletedProductGroups
                        : data.productGroups.totalProductGroups,
                );
            },
        },
    );

    const [saveGroup] = useMutation(ADD_PRODUCT_GROUPS, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_PRODUCT_GROUPS],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setMessage(SUCCESSFULLY_STATUS_UPDATE_GROUP);
            setShowConfirmationModal(false);
            setShowWarningModal(false);
            setSelectedMenuItem('');
            setQueryError(false);
            setShowToast(true);
            setTimeout(() => {
                setStatusUpdateGroupLoading(false);
                /* history.push('/products/groups'); */
            }, 2000);
        },
        onError: (error) => {
            setStatusUpdateGroupLoading(false);
            setSelectedMenuItem('');
            setShowConfirmationModal(false);

            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1645) {
                    setWarningModalTitle('Error');
                    setWarningModalBody(
                        'This group cannot be inactivated/trashed as it is currently assigned to an active deal.',
                    );
                    setShowWarningModal(true);
                }
            } else {
                setShowWarningModal(false);
                setQueryError(true);
                setMessage(ERROR_STATUS_UPDATE_GROUP);
                setShowToast(true);
            }
        },
    });

    const productGroupNames = productsGroupData
        ?.map((productGroupItem) => productGroupItem?.name)
        .concat(productsGroupData?.map((productGroupItem) => productGroupItem?.productGroupId));

    useEffect(() => {
        if (isFilterClicked === true) {
            getProductGroupData({
                variables: {
                    filter: {
                        productType: selectedProductType,
                        active: selectedTab === 2 ? true : selectedTab === 3 ? false : null,
                        deleted: selectedTab === 4 ? true : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: null,
                    order: null,
                    searchText: searchTerm && searchClicked ? searchTerm : null,
                },
            });
        } else if (isFilterClicked === false) {
            getProductGroupData({
                variables: {
                    filter: {
                        productType: null,
                        active: selectedTab === 2 ? true : selectedTab === 3 ? false : null,
                        deleted: selectedTab === 4 ? true : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: null,
                    order: null,
                    searchText: searchTerm && searchClicked ? searchTerm : null,
                },
            });
            setSelectedProductType('0');
            setIsSelectedProductTypeRemoved(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        pageSize,
        selectedTab,
        isFilterClicked,
        searchTerm,
        searchClicked,
        selectedProductType,
    ]);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize]);

    useEffect(() => {
        dispatch(getAllUserDetails());
    }, [dispatch]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setShowWarningModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        const instantMenuList = [];

        if (selectedTab === 4) {
            instantMenuList.push({
                text: 'Restore',
                value: INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP,
            });
        } else {
            instantMenuList.push(
                {
                    text: 'View',
                    value: INSTANT_EDIT_OPTION_VIEW_PRODUCT_GROUP,
                },
                {
                    text: 'Edit',
                    value: INSTANT_EDIT_OPTION_EDIT_PRODUCT_GROUP,
                },
                {
                    text: 'Active',
                    value: INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP,
                },
                {
                    text: 'Inactive',
                    value: INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP,
                },
                {
                    text: 'Trash',
                    value: INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP,
                },
            );
        }

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };
    const columnsTab = React.useMemo(
        () => [
            { id: 'id', Header: 'Group ID', accessor: 'productGroupId' },

            {
                id: 'name',
                Header: 'Group Title',
                accessor: 'name',
                Cell: function productGroupCell({ row }) {
                    return (
                        <Link
                            className="hover:underline"
                            rel="noreferrer"
                            target="_blank"
                            to={`/products/groups/view/${row?.original?.id}`}
                        >
                            {row?.original?.name}
                        </Link>
                    );
                },
            },
            {
                id: 'displayName',
                Header: 'Display Name',
                accessor: 'displayName',
            },
            {
                id: 'productType',
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                id: 'count',
                Header: 'Product Count',
                accessor: 'productsCount',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'isActive',
                Cell: function showStatus({ row }) {
                    return (
                        <>
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                    row?.original?.isActive === true
                                        ? 'bg-green-100 text-green-600'
                                        : row?.original?.isActive === false
                                        ? 'bg-red-100 text-red-600'
                                        : ''
                                }`}
                            >
                                {row?.original?.isActive != true && selectedTab == 4
                                    ? 'Trashed'
                                    : row?.original?.isActive === true
                                    ? 'Active'
                                    : 'Inactive'}
                            </span>
                            {row?.original?.featured ? (
                                <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-yellow-200 text-yellow-600 ml-1">
                                    Featured
                                </span>
                            ) : null}
                        </>
                    );
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: function showActionColumn({ row }) {
                    return (
                        <div
                            className="cursor__pointer"
                            id={`${row?.original?.userId}-action-button`}
                        >
                            <button
                                value="menu cursor-pointer"
                                className="text-gray -500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                            >
                                <BsIcons.BsThreeDots className="mx-auto" />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [selectedTab],
    );

    useEffect(() => {
        if (selectedMenuItem === INSTANT_EDIT_OPTION_VIEW_PRODUCT_GROUP && clickedGroup) {
            history.push(`/products/groups/view/${clickedGroup?.id}`);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_EDIT_PRODUCT_GROUP && clickedGroup) {
            history.push(`/products/groups/edit/${clickedGroup?.id}`);
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP &&
            clickedGroup
        ) {
            setConfirmationMessageTitle('Confirm Inactivation');
            setConfirmationMessage('Do you want to inactivate the selected product group ?');
            setShowConfirmationModal(true);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP && clickedGroup) {
            if (clickedGroup?.productsCount > 0) {
                setConfirmationMessageTitle('Confirm Activation');
                setConfirmationMessage('Do you want to activate the selected product group ?');
                setShowConfirmationModal(true);
            } else {
                setWarningModalTitle('Cannot activate product group');
                setWarningModalBody(
                    'This Group contains 0 items, therefore it cannot be activated.',
                );
                setShowWarningModal(true);
            }
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP && clickedGroup) {
            setConfirmationMessageTitle('Confirm Trash');
            setConfirmationMessage('Do you want to trash the selected product group ?');
            setShowConfirmationModal(true);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP && clickedGroup) {
            setConfirmationMessageTitle('Confirm Restoration');
            setConfirmationMessage('Do you want to restore the selected product group ?');
            setShowConfirmationModal(true);
        }
    }, [history, selectedMenuItem, selectedTab]);

    const handleProductGroupStatusUpdate = () => {
        let isActive = null;
        let isDeleted = null;
        if (selectedMenuItem === INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP && clickedGroup) {
            isActive = false;
            isDeleted = false;
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP && clickedGroup) {
            isActive = true;
            isDeleted = false;
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP && clickedGroup) {
            isActive = false;
            isDeleted = true;
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP && clickedGroup) {
            isActive = false;
            isDeleted = false;
        }

        if (
            (selectedMenuItem === INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP ||
                selectedMenuItem === INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP ||
                selectedMenuItem === INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP ||
                selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP) &&
            clickedGroup
        ) {
            setStatusUpdateGroupLoading(true);
            saveGroup({
                variables: {
                    productGroup: {
                        id: clickedGroup?.id,
                        name: clickedGroup?.name,
                        productType: clickedGroup?.productType,
                        featured: clickedGroup?.featured,
                        isActive: isActive,
                        isDeleted: isDeleted,
                    },
                },
            });
        }
    };

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <div className="w-full ml-4 pt-4">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        error={queryError}
                        margin="ml-1"
                        width="w-10/12"
                        selfDestruct={true}
                        selfDestructTimer={2000}
                    />
                )}

                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        productGroupsCounts={productGroupCounts}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        isProcessing={statusUpdateGroupLoading}
                        setYesBtnClick={() => handleProductGroupStatusUpdate()}
                        setNoBtnClick={() => {
                            setShowWarningModal(false);
                            setShowConfirmationModal(false);
                            setSelectedMenuItem('');
                        }}
                    />
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={warningModalBody}
                        warningModalTitle={warningModalTitle}
                        setProcessFinishedClicked={setWarningModalOkayClicked}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div className="flex realative lg:mr-4">
                        <button
                            id="filter-button"
                            onKeyDown={() => {
                                setIsFilterClicked(!isFilterClicked);
                            }}
                            onClick={() => setIsFilterClicked(!isFilterClicked)}
                            className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                            }`}
                        >
                            <span className="flex">
                                <BsIcons.BsFillFunnelFill /> <span className="ml-2">Filter</span>
                            </span>
                        </button>
                    </div>
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="user-list-search-field"
                            options={productGroupNames}
                            handleChange={(e, newVal, reason) => {
                                handleChange(e, newVal, reason);
                                setSearchClicked(true);
                            }}
                            placeHolder={'Search groups by id and name'}
                        />
                    </div>
                </div>
                <Buttons
                    name="Add New Group"
                    type="submit"
                    buttonType="primary"
                    id="invite"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        history.push('/products/groups/add');
                    }}
                />
                <AddUserOptionModal
                    setShowAddNewUser={setShowAddNewUser}
                    showAddNewUser={showAddNewUser}
                    setInviteUserFormModal={setInviteUserFormModal}
                />
                {!showAddNewUser ? (
                    <>
                        <InviteUserFormModal
                            setShowInviteNewUser={setInviteUserFormModal}
                            showInviteNewUser={inviteUserFormModal}
                        />
                    </>
                ) : null}
            </div>
            {isFilterClicked ? (
                <>
                    <div className="flex flex-row w-full h-24 md-24 p-2">
                        <div className="relative w-1/3 px-2">
                            <div className="flex flex-wrap flex-col relative">
                                <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Filter by product type
                                </div>

                                <select
                                    className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                    id="filter-by-role"
                                    placeholder="actions"
                                    onChange={(e) => {
                                        setSelectedProductType(e.target.value);
                                        setIsSelectedProductTypeRemoved(false);
                                    }}
                                    onBlur={(e) => {
                                        setSelectedProductType(e.target.value);
                                        setIsSelectedProductTypeRemoved(false);
                                    }}
                                >
                                    {isSelectedProductTypeRemoved ? (
                                        <option value="" disabled selected>
                                            Select product type filters
                                        </option>
                                    ) : null}
                                    <option value={ProductType.VOUCHER} className={'font-medium'}>
                                        Voucher
                                    </option>
                                    <option value={ProductType.TOPUP} className={'font-medium'}>
                                        Topup
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {isFilterClicked && !isSelectedProductTypeRemoved && selectedProductType?.length ? (
                <div className="bg-purple-100 w-full h-20 flex  -mt-3 py-2 px-4 my-5 ">
                    <div className="w-full -mt-2">
                        <div className="flex flex-col">
                            <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                <div className="flex">
                                    {selectedProductType ? (
                                        <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                            <div className="font-poppins mt-1 mr-3">
                                                {selectedProductType === ProductType.VOUCHER
                                                    ? 'Voucher'
                                                    : selectedProductType === ProductType.TOPUP
                                                    ? 'Topup'
                                                    : null}
                                            </div>
                                            <button
                                                className="text-purple-700 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedProductType(null);
                                                    setIsSelectedProductTypeRemoved(true);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {!isSelectedProductTypeRemoved ? (
                                    <div className="w-auto h-auto p-3 border-l-2 border-gray-300">
                                        <div className="right-0 my-auto  mx-10 border-l-2 flex underline text-lg">
                                            <button
                                                className="text-purple-400 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedProductType(null);
                                                    setIsSelectedProductTypeRemoved(true);
                                                }}
                                            >
                                                Clear all
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="w-full flex-col">
                <>
                    {productsGroupData?.length || productGroupsLoading ? (
                        <DataTable
                            columns={columnsTab}
                            data={productsGroupData}
                            serverSidePagination={true}
                            pageCount={pageCount}
                            setPageIndex={setPageIndex}
                            setDefaultPageSize={setPageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSizes={pageSize}
                            loading={productGroupsLoading}
                            menuItems={instantEditMenuItems}
                            setExposeMenuClickedItem={setClickedGroup}
                            setSelectedMenuItem={setSelectedMenuItem}
                            selectedTab={selectedTab}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                            totalItems={totalItems}
                        />
                    ) : isFilterClicked && !productsGroupData?.length ? (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columnsTab?.map((column) => {
                                    return (
                                        <div className="text-black font-poppins font-semibold flex ml-12">
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found for selected filter.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    ) : searchClicked && !productsGroupData?.length ? (
                        <div className="flex p-10 justify-center">
                            <div className=" mx-1 ">Your search</div>
                            <div className="text-purple-500">{`"${searchTerm}"`}</div>
                            <div className=" mx-1 ">did not match any results</div>
                        </div>
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columnsTab?.map((column) => {
                                    return (
                                        <div className="text-black font-poppins font-semibold flex ml-12">
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
}
