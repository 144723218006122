/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useLazyQuery } from '@apollo/client';
import { BsThreeDots } from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';

import TableTabView from '../productScreens/TableTabView';
import SearchBar from '../../ui/molecules/SearchBar';
import { Link, useHistory } from 'react-router-dom';
import { Buttons } from '../../ui/atoms/Button';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import DataTable from '../../ui/organisms/DataTable';
import Toast from '../../ui/atoms/Toast';
import { FETCH_DEALS } from '../../../queries/DealQueries';
import { AllDealsResponse, DealStatus } from '../../../types/deals';
import { INSTANT_EDIT_OPTION_EDIT_DEAL } from '../../../constants/deal';
import { saveDealId } from '../../../redux/rootActions';
import { useDispatch } from 'react-redux';
import { viewDateTimeUTC } from '../../../helpers/DateTimeHelpers.helpers';

export default function DealListScreen() {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [showToast, setShowToast] = useState(false);
    const history = useHistory();
    const [clickedDeal, setClickedDeal] = useState(null);

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [message, setMessage] = useState('');
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [searchClicked, setSearchClicked] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [error, setError] = useState(false);

    const [dealsData, setDealsData] = useState([]);

    const [dealsCounts, setDealsCounts] = useState({
        all: 0,
        ongoing: 0,
        scheduled: 0,
        ended: 0,
    });

    const [getDealsData, { loading: dealsLoading }] = useLazyQuery(FETCH_DEALS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data: AllDealsResponse) => {
            setDealsData(data.deals.deals);
            setDealsCounts({
                all: data.deals.totalDeals,
                ongoing: data.deals.totalOngoingDeals,
                scheduled: data.deals.totalScheduledDeals,
                ended: data.deals.totalEndedDeals,
            });
            setTotalItems(data.deals.totalDeals);
        },
    });

    const dealSuggestions: string[] = dealsData?.map((deal) => deal?.dealName);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize]);

    useEffect(() => {
        getDealsData({
            variables: {
                filter: {
                    dealStatus:
                        selectedTab === 2
                            ? 'ONGOING'
                            : selectedTab === 3
                            ? 'SCHEDULED'
                            : selectedTab === 4
                            ? 'ENDED'
                            : null,
                },
                offset: currentPage - 1 || 0,
                limit: Math.ceil(pageSize) || 10000,
                sort: null,
                order: null,
                searchText: searchTerm && searchClicked ? searchTerm : null,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageSize, selectedTab, searchTerm, searchClicked]);

    useEffect(() => {
        const instantMenuList = [{ text: 'Edit', value: INSTANT_EDIT_OPTION_EDIT_DEAL }];

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    useEffect(() => {
        if (selectedMenuItem === INSTANT_EDIT_OPTION_EDIT_DEAL && clickedDeal) {
            history.push(`/deal/general-details-edit/${clickedDeal?.id}`);
            dispatch(saveDealId(clickedDeal?.id));
        }
    }, [clickedDeal, history, selectedMenuItem, selectedTab]);

    const columns = React.useMemo(
        () => [
            { id: 'deal-name', Header: 'Deal Name', accessor: 'dealName' },
            {
                id: 'name',
                Header: 'Percentage',
                accessor: 'dealPercentage',
                Cell: function dealPercentageCell({ value }) {
                    return <span>{value} %</span>;
                },
            },
            {
                id: 'product-count',
                Header: 'Product Count',
                accessor: 'productCount',
            },
            {
                id: 'start-date',
                Header: 'Start Date',
                accessor: 'startDateTime',
                Cell: function startDateCell({ value }) {
                    // const startDateTime = new Date(value).toISOString();
                    const startDateTime = viewDateTimeUTC(value);
                    return <span>{startDateTime}</span>;
                },
            },
            {
                id: 'end-date',
                Header: 'End Date',
                accessor: 'endDateTime',
                Cell: function endDateCell({ value }) {
                    const endDateTime = viewDateTimeUTC(value);
                    return <span>{endDateTime}</span>;
                },
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'status',
                Cell: function dealStatusCell({ row }) {
                    if (row?.original?.status === DealStatus[DealStatus.SCHEDULED]) {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-yellow-100 text-yellow-600">
                                Scheduled
                            </span>
                        );
                    } else if (row?.original?.status === DealStatus[DealStatus.ONGOING]) {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-green-100 text-green-600">
                                Ongoing
                            </span>
                        );
                    } else if (row?.original?.status == DealStatus[DealStatus.ENDED]) {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-red-100 text-red-600">
                                Ended
                            </span>
                        );
                    } else {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm">
                                {row?.original?.status}
                            </span>
                        );
                    }
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: function showActionColumn({ row }) {
                    return (
                        <>
                            {row?.original?.status != DealStatus[DealStatus.ENDED] && (
                                <div
                                    className="cursor__pointer"
                                    id={`${row?.original?.id}-action-button`}
                                >
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray -500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                                    >
                                        <BsThreeDots className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    margin="ml-1"
                    width="w-10/12"
                    selfDestruct={true}
                    selfDestructTimer={2000}
                />
            )}
            <div className="w-full ml-4 pt-4">
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        dealsCount={dealsCounts}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="deals-search-field"
                            options={dealSuggestions}
                            handleChange={(e, newVal, reason) => {
                                handleChange(e, newVal, reason);
                                setSearchClicked(true);
                            }}
                            placeHolder={'Search by product name, id or deal name'}
                        />
                    </div>
                </div>
                <Buttons
                    name="Add New Deal"
                    type="submit"
                    buttonType="primary"
                    id="add-new-deal"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        history.push('/deals/general-details-add');
                    }}
                />
            </div>

            <div className="w-full flex-col">
                {dealsData?.length || dealsLoading ? (
                    <DataTable //without selection of row
                        columns={columns}
                        data={dealsData}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex}
                        serverSidePagination={true}
                        setDefaultPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSizes={pageSize}
                        menuItems={instantEditMenuItems}
                        loading={dealsLoading}
                        setSelectedMenuItem={setSelectedMenuItem}
                        setExposeMenuClickedItem={setClickedDeal}
                        selectedTab={selectedTab}
                        isInitialLoad={isInitialLoad}
                        setIsInitialLoad={setIsInitialLoad}
                        totalItems={totalItems}
                        dealPage={true}
                    />
                ) : !dealsData?.length ? (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No deals found for the searched query</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                ) : searchClicked && !dealsData?.length ? (
                    <div className="flex p-10 justify-center">
                        <div className=" mx-1 ">Your search</div>
                        <div className="text-purple-500">{`"${searchTerm}"`}</div>
                        <div className=" mx-1 ">did not match any results</div>
                    </div>
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
