/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import UploadDocumentComponent from './UploadDocumentComponent';
import { Modal } from 'semantic-ui-react';
import { BASE_URL_CSV_AWS_S3, SORT_DSC } from '../../../constants/common';
import { Buttons } from '../../ui/atoms/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import SuccessMessageModal from './SuccessMessageModal';
import {
    API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_FORMAT_ERROR_CODE,
    API_SUPPLIER_TOPUP_CONFIG_CSV_MISSING_FIELDS_ERROR_CODE,
    ERROR,
    ERROR_MESSAGE,
    INVALID_FILE_FORMAT_TITLE,
    MISSING_FIELDS_TITLE,
    SUCCESS,
    SUCCESS_CSV_UPLOAD,
    TOPUP_CONFIG_CSV_VALIDATION_ERROR,
    VALIDATION_ERROR,
} from '../../../constants/supplier';
import WarningMessageModal from './WarningMessageModal';
import Loader from '../../../utils/loader';
import {
    FETCH_SUPPLIER_FILES,
    PROCESS_ORDER_FIELDS_CSV,
    SAVE_CSV,
    UPLOAD_TOPUP_CONFIG_ORDER_FIELDS,
} from '../../../queries/CsvQueries';
import { FileStatus, FileUploadType } from '../../../enums/csvFile';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { BsDownload, BsExclamationCircle } from 'react-icons/bs';
import TopupConfigCsvUploadMismatchModal from './TopupConfigCsvUploadMismatchModal';
import { awsSDKConfig, awsSDKS3Params } from '../../../constants/config/constants';
import AWS from 'aws-sdk';
import { useHistory } from 'react-router';

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    supplierId?: any;
    setDraftData?: any;
    setErrorData?: any;
    setFilesName?: any;
    setFileData?: any;
    csvtype?: FileUploadType;
    orderFieldsCSVMismatches: any[];
    supplier?: any;
    handleClose: () => void;
}

export default function UploadTopupConfigurationCsv({
    showModal,
    setShowModal,
    supplierId,
    setDraftData,
    setFilesName,
    setErrorData,
    setFileData,
    csvtype,
    orderFieldsCSVMismatches,
    supplier,
    handleClose,
}: Props) {
    const [fileLocation, setFileLocation] = useState(null);
    const [file, setFile] = useState(null);
    const [maxSize, setMaxSize] = useState(25);
    const [isComplete, setIsComplete] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);
    const [warningFinishedClicked, setWarningFinishedClicked] = useState(false);
    const [uploadCSVSuccess, setUploadCSVSucess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [fileName, setFileName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const timestamp = new Date().valueOf();
    const [previousUpload, setPreviousUpload] = useState([]);
    const [haveMismatch, setHaveMismatch] = useState(false);
    const [showMismatchWarningModal, setShowMismatchWarningModal] = useState(false);
    const [continueWithMismatch, setContinueWithMismatch] = useState(false);
    const [clickedUser, setClickedUser] = useState(null);
    const [lastCsvFile, setLastCsvFile] = useState(null);
    const [fileId, setFileId] = useState(0);
    const [mismatchesAfterCSVProccessed, setMismatchesAfterCSVProccessed] = useState([]);
    const [errorTitle, setErrorTitle] = useState(ERROR);

    /**
        This function creates a temporary <a> tag which is linked to the CSV file
        retrieved from S3, and simulates clicking it, which achieves the download effect.
    */
    const handleDownloadCSVButtonClick = async (filePath: string, fileName: string) => {
        const s3 = new AWS.S3(awsSDKConfig);
        const params = {
            Bucket: awsSDKS3Params?.params?.Bucket,
            Key: filePath,
        };

        try {
            const data: AWS.S3.GetObjectOutput = await s3.getObject(params).promise();
            const blob = new Blob([data.Body as BlobPart], { type: data.ContentType });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName || 'orderFields.csv';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
        }
    };

    const [loadFiles, { loading: filesLoading, error: filesError }] = useLazyQuery(
        FETCH_SUPPLIER_FILES,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPreviousUpload(data?.csvFiles?.files);
            },
        },
    );

    const history = useHistory();

    const [processOrderFieldsCSVFile, { loading: prcoessQueryLoading, error: processQueryError }] =
        useMutation(PROCESS_ORDER_FIELDS_CSV, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setMismatchesAfterCSVProccessed(data?.processOrderFieldsCSVFile);
                setLoading(false);
                if (data?.processOrderFieldsCSVFile?.length > 0) {
                    setShowMismatchWarningModal(true);
                } else {
                    uploadDraftProducts({
                        variables: {
                            fileId: fileId,
                        },
                    });
                }
            },
            onError: (error) => {
                setLoading(false);
                setErrorMessage(
                    error?.graphQLErrors[0]?.extensions?.errorCode ===
                        API_SUPPLIER_TOPUP_CONFIG_CSV_MISSING_FIELDS_ERROR_CODE ||
                        error?.graphQLErrors[0]?.extensions?.errorCode ===
                            API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_FORMAT_ERROR_CODE
                        ? error?.message
                        : TOPUP_CONFIG_CSV_VALIDATION_ERROR,
                );
                setErrorTitle(
                    error?.graphQLErrors[0]?.extensions?.errorCode ===
                        API_SUPPLIER_TOPUP_CONFIG_CSV_MISSING_FIELDS_ERROR_CODE
                        ? MISSING_FIELDS_TITLE
                        : error?.graphQLErrors[0]?.extensions?.errorCode ===
                          API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_FORMAT_ERROR_CODE
                        ? INVALID_FILE_FORMAT_TITLE
                        : ERROR,
                );
                setShowWarningModal(true);
            },
        });

    const [uploadDraftProducts] = useMutation(UPLOAD_TOPUP_CONFIG_ORDER_FIELDS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setTimeout(() => {
                setUploadCSVSucess(true);
                setShowModal(true);
                setContinueWithMismatch(false);
            }, 1000);
        },
    });

    const [saveCSVFiles, { loading: csvQueryLoading, error: csvQueryError }] = useMutation(
        SAVE_CSV,
        {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                if (data?.saveCSVFile?.id) {
                    setFileData(data?.saveCSVFile?.id);
                    setFileId(data?.saveCSVFile?.id);
                    processOrderFieldsCSVFile({
                        variables: {
                            fileId: data?.saveCSVFile?.id,
                        },
                    });
                }
            },
            onError: (error) => {
                setLoading(false);
                setShowWarningModal(true);
            },
        },
    );

    useQuery(FETCH_SUPPLIER_FILES, {
        variables: {
            filter: {
                fileStatus: FileStatus.COMPLETED,
                supplierId: supplierId,
                fileUploadType: FileUploadType.ORDER_FIELDS_UPLOAD,
            },
            limit: 1,
            offset: 0,
            sort: 'createdDate',
            order: SORT_DSC,
        },
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            const csvFile = {
                ...data?.csvFiles?.files[0],
            };

            const dateTime = new Date(csvFile.modifiedDate);
            const day = String(dateTime.getDate()).padStart(2, '0');
            const month = String(dateTime.getMonth() + 1).padStart(2, '0');
            const year = dateTime.getFullYear();
            const hours = String(dateTime.getHours()).padStart(2, '0');
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');

            const formattedDate = `${day}/${month}/${year}`;
            const formattedTime = `${hours}:${minutes}`;
            csvFile.lastUpdatedDate = formattedDate;
            csvFile.lastUpdatedTime = formattedTime;

            setLastCsvFile([csvFile]);
        },
        onError: (error) => {
            console.error('error', error);
        },
    });

    const csvFiles = {
        url: fileLocation,
        fileName: file?.name,
        fileSize: file?.size,
        fileType: file?.type,
    };

    useEffect(() => {
        if (csvFiles?.fileName) {
            setFilesName(csvFiles?.fileName);
        }
    }, [csvFiles?.fileName]);

    useEffect(() => {
        if (isComplete && supplierId && csvFiles?.url && file?.name) {
            setLoading(true);
            saveCSVFiles({
                variables: {
                    supplierId: supplierId,
                    fileUrl: csvFiles?.url,
                    fileName: file?.name,
                    fileUploadType: csvtype,
                },
            });
        }
    }, [isComplete, supplierId, csvFiles?.url, file?.name]);

    useEffect(() => {
        if (processFinishedClicked) {
            setTimeout(() => {
                if (!prcoessQueryLoading && !csvQueryLoading) {
                    setUploadCSVSucess(false);
                    setShowModal(false);
                    handleClose();
                }
            }, 1000);
            setTimeout(() => {
                if (!prcoessQueryLoading && !csvQueryLoading) {
                    setShowModal(false);
                    handleClose();
                }
            }, 100);
        }
    }, [processFinishedClicked, prcoessQueryLoading, csvQueryLoading]);

    useEffect(() => {
        if (errorMessage != '') {
            setShowWarningModal(true);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (orderFieldsCSVMismatches?.length > 0) {
            setHaveMismatch(true);
        } else {
            setHaveMismatch(false);
        }
    }, [orderFieldsCSVMismatches]);

    useEffect(() => {
        setFileName(BASE_URL_CSV_AWS_S3 + timestamp + 1);
    }, []);

    useEffect(() => {
        if (showModal) {
            loadFiles({
                variables: {
                    filter: {
                        fileUploadType: FileUploadType.ORDER_FIELDS_UPLOAD,
                        supplierId: supplierId,
                        fileStatus: FileStatus.COMPLETED,
                    },
                    offset: 0,
                    limit: 1,
                    sort: 'createdDate',
                    order: SORT_DSC,
                },
            });
        }
    }, [showModal]);

    useEffect(() => {
        if (continueWithMismatch && fileId) {
            history.push({
                pathname: '/suppliers/view-csv-mismatches',
                state: {
                    orderFieldsCSVMismatches: mismatchesAfterCSVProccessed,
                    supplier: supplier,
                },
            });

            uploadDraftProducts({
                variables: {
                    fileId: fileId,
                },
            });
        }
    }, [continueWithMismatch]);

    const uploadedTopupFieldConfigCsvColumns = React.useMemo(
        () => [
            {
                Header: 'Last Updated Date',
                accessor: 'lastUpdatedDate',
            },
            {
                Header: 'Time',
                accessor: 'lastUpdatedTime',
            },
            {
                Header: 'File Name',
                accessor: 'fileName',
            },
            {
                id: 'downloadCSV',
                Cell: function downloadCsv({ row }) {
                    return (
                        <button
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center`}
                            onClick={() =>
                                handleDownloadCSVButtonClick(
                                    row.original?.fileUrl,
                                    row.original?.fileName,
                                )
                            }
                        >
                            <BsDownload />
                        </button>
                    );
                },
            },
        ],
        [],
    );

    const handleReviewMistmatchesBtnClick = () => {
        history.push({
            pathname: '/suppliers/view-csv-mismatches',
            state: { orderFieldsCSVMismatches: orderFieldsCSVMismatches, supplier: supplier },
        });
    };

    return (
        <Modal
            open={showModal}
            onClose={() => {
                setShowModal(false);
                handleClose();
            }}
            center={true.toString()}
            focustrapped={false.toString()}
            size={'large'}
            className="p-10 font-poppins"
        >
            <div className="text-center text-xl font-bold pb-5">Top-up Field Configuration</div>
            {previousUpload && previousUpload?.length > 0 && (
                <div>
                    <DataTableAlter
                        columns={uploadedTopupFieldConfigCsvColumns}
                        data={lastCsvFile}
                        radioBtn={false}
                        hidePagination={true}
                        setExposeClickedItem={setClickedUser}
                    />
                </div>
            )}
            {haveMismatch && (
                <button
                    className="py-5 text-lg text-red-500 flex gap-2 items-center underline"
                    onClick={() => handleReviewMistmatchesBtnClick()}
                >
                    <span>
                        <BsExclamationCircle />
                    </span>
                    Review Mismatches
                </button>
            )}
            <div className="text-start text-xl py-5">Upload the File</div>
            <div className="pl-8 pr-2 pt-2 p-10">
                {csvQueryLoading ? (
                    <Loader />
                ) : prcoessQueryLoading ? (
                    <Loader />
                ) : loading ? (
                    <Loader />
                ) : (
                    <UploadDocumentComponent
                        isEdit={false}
                        setFile={setFile}
                        maxSize={maxSize}
                        uploadDocTypeHint={'You can upload your files in csv format.'}
                        s3DirName={fileName ? fileName : '' + BASE_URL_CSV_AWS_S3 + timestamp + 1}
                        setFileLocation={setFileLocation}
                        setCompletedCsv={setCompleted}
                        isCsv={true}
                        setIsComplete={setIsComplete}
                        setErrorMessage={setErrorMessage}
                        setErrorTitle={setErrorTitle}
                    />
                )}
            </div>
            <TopupConfigCsvUploadMismatchModal
                showWarningModal={showMismatchWarningModal}
                setShowWarningModal={setShowMismatchWarningModal}
                setContinueWithMismatch={setContinueWithMismatch}
            />
            <SuccessMessageModal
                showSuccessModal={uploadCSVSuccess}
                setShowSuccessModal={setUploadCSVSucess}
                successModalTitle={SUCCESS}
                successModalBody={SUCCESS_CSV_UPLOAD}
                setProcessFinishedClicked={setProcessFinishedClicked}
            />
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={errorMessage ? errorMessage : ERROR_MESSAGE}
                warningModalTitle={errorTitle ? errorTitle : ERROR}
                setProcessFinishedClicked={setWarningFinishedClicked}
            />
            <div className="mt-12 justify-end flex">
                <Buttons
                    name="Close"
                    buttonType="secondary-main-grey"
                    id="close-button"
                    size="e-small"
                    padding="py-2 mr-4"
                    onclick={() => {
                        setShowModal(false);
                        handleClose();
                    }}
                />
            </div>
        </Modal>
    );
}
